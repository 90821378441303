import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';
import {theme} from 'styles/theme';

import {getResource} from 'src/functionality/resources-provider';
import Tile from 'src/components/functional/tile';

import Page from 'src/components/functional/page';

import 'styles/legal.scss';
import ContentBlock from 'src/components/functional/content-block';
import {H3} from 'src/components/functional/headings';
import {usePageContent} from 'src/functionality/content-provider';
import {useLocation} from '@reach/router';

const DownloadsWrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  padding: 1em;
  margin: 0.5em 0;
`;

const downloads = [
  {
    title: 'Website Terms of Use',
    url: 'resources/Policies-plans-and-procedures/Website-Terms-of-Use.pdf',
  },
  {
    title: 'Confidentiality and Privacy Policy',
    // eslint-disable-next-line max-len
    url: 'resources/Policies-plans-and-procedures/Confidentiality-and-Privacy-Policy.pdf',
  },
];

const LegalPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  return (
    <Page>
      <section className="legal-section">
        <Container className="root-container text-center shadow">
          <ContentBlock
            header={(props) => <></>}
            content={content}
            tag='L01'
          />
          <DownloadsWrapper>
            {
              downloads.map((download, key) =>
                <Tile
                  key={key}
                  isRounded={true}
                  centered={true}
                  type='document'
                  onClick={() =>
                    getResource(download.url)
                        .then((url) => window.open(url))}
                  title={download.title}
                />,
              )}
          </DownloadsWrapper>
        </Container>
      </section>
    </Page>
  );
};

LegalPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default LegalPage;


